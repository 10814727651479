<template>
  <v-row>
    <v-col v-if="mdAndUp">
      <v-divider class="top-divider" thickness="2"></v-divider>
    </v-col>
    <v-col>
      <v-container class="container" ref="">
        <v-toolbar height="54" class="elevation-4" dense floating>
          <v-menu
            v-for="(item, key) in items"
            :key="key"
            transition="scale-transition"
            offset-y
            :attach="disableTeleportation"
          >
            <template v-slot:activator="{ props }">
              <v-btn
                class="btn-dark"
                v-bind="props"
                :class="{
                  active: isActive(item),
                }"
                @click="
                  item.children?.length
                    ? null
                    : navigateToUrl(localePath(item.route_url))
                "
                data-test="navButton"
              >
                <v-icon left class="mr-3"
                  >mdi-{{ item.icon.replace(/\s+/g, '-') }}
                </v-icon>
                {{ item.verbose_name }}
                <v-icon
                  v-if="item.children && item.children.length"
                  right
                  class="ml-3"
                >
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </template>

            <v-list v-if="item.children && item.children.length">
              <v-list-item v-for="(child, i) in item.children" :key="i">
                <v-list-item-title
                  @click="navigateToUrl(localePath(child.route_url))"
                  data-test="navList"
                >
                  <v-icon left class="mr-3"
                    >mdi-{{ child.icon.replace(/\s+/g, '-') }}
                  </v-icon>
                  {{ child.verbose_name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-container>
    </v-col>
    <v-col v-if="mdAndUp">
      <v-divider class="top-divider" thickness="2"></v-divider>
    </v-col>
  </v-row>
</template>
<script setup lang="ts">
import { useRoute } from 'vue-router';
import { PropType, ref } from 'vue';
import { navigateToUrl } from 'single-spa';
import { localePath } from '@/composables/index';
import { NavigationItems } from '../interface/navigation';
import { useDisplay } from 'vuetify';
const route = useRoute();

const { mdAndUp } = useDisplay();
defineProps({
  items: {
    type: Array as PropType<NavigationItems[]>,
    required: true,
  },
});
const disableTeleportation = ref(false);

const isActive = (item: NavigationItems): boolean => {
  if (route.path === '/') return false;
  let regex = /\/(en|ar)(\/.*)/;
  let match = route.path.match(regex);
  let normalizedRoute = match ? match[2] : '';
  if (
    item?.children?.some((element) => element.route_url === normalizedRoute)
  ) {
    return true;
  }
  if (item.route_url === normalizedRoute) {
    return true;
  }
  return false;
};

defineExpose({
  disableTeleportation,
});
</script>

<style scoped lang="scss">
.top-divider {
  margin-top: 46px !important;
  height: 5px;
  width: auto;
  margin: auto;
}
.v-toolbar {
  padding: 0 5px;
  background: #1e1e1e;
  border-radius: 12px;
  min-height: 60px !important;
  justify-content: center;
  :deep(.v-toolbar__content) {
    justify-content: center;
  }
}
// .container {
//   @media screen and (max-width: 1280px) {
//     display: none;
//   }
// }
.btn-dark {
  height: 44px !important;
  margin: 0 5px;
  background-color: #303030;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 32px 10px 32px;
  letter-spacing: normal;
  text-transform: capitalize;
  &.active {
    background-color: #f77d1a;
  }
  &.activeWidth {
    width: 200px;
  }
}
.v-menu > .v-overlay__content > .v-list {
  background-color: #303030;
  cursor: pointer;
  .v-list-item-title {
    color: white;
  }
}
</style>

<template>
  <v-theme-provider theme="dark" with-background>
    <v-toolbar height="72">
      <!-- working for small screen navigation drawer -->
      <!-- <div class="d-flex justify-center align-center">
        <v-icon
          icon="mdi-menu"
          class="menu-icon"
          @click="$emit('toggleDrawer')"
        ></v-icon> -->
      <img src="../assets/images/ottu-logo.png" alt="Ottu" class="ottu-logo" />
      <!-- </div> -->
      <div class="w-33">
        <v-text-field
          density="compact"
          placeholder="Search..."
          prepend-inner-icon="mdi-magnify"
          variant="solo"
          hide-details="auto"
        ></v-text-field>
      </div>
      <div class="d-flex justify-center align-center header-info">
        <v-badge :content="2" size="small" bordered rounded="md">
          <v-icon icon="mdi-bell"></v-icon>
        </v-badge>
        <v-divider vertical thickness="4"></v-divider>
        <div class="user-menu">
          <img src="../assets/images/Frame 457.png  " class="user-img" />
          <div>{{ userDetails.username }}</div>
          <v-menu :attach="disableTeleportation">
            <template v-slot:activator="{ props }">
              <v-btn icon v-bind="props" data-test="chevron-button">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-theme-provider theme="light" with-background>
              <v-card
                v-if="userDetails.username === undefined"
                class="action-menu-loader"
              >
                <v-skeleton-loader
                  max-width="250"
                  type="list-item-avatar@4"
                ></v-skeleton-loader>
              </v-card>
              <v-card v-else>
                <v-list min-width="250">
                  <v-list-item
                    max-width="250px"
                    v-if="userDetails.is_superuser && merchantDetails.admin_url"
                    :href="merchantDetails.admin_url"
                  >
                    <template v-slot:prepend>
                      <v-icon icon="mdi-cog-outline"></v-icon>
                    </template>
                    <v-list-item-title>{{
                      $t('Administration panel')
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    max-width="250px"
                    @click="switchLanguage(route, router)"
                  >
                    <template v-slot:prepend>
                      <v-icon icon="mdi-web"></v-icon>
                    </template>
                    <v-list-item-title>{{
                      $t('Switch language')
                    }}</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    :to="{ path: localePath('/user') }"
                    max-width="250px"
                  >
                    <template v-slot:prepend>
                      <v-icon icon="mdi-account-box"></v-icon>
                    </template>
                    <v-list-item-title>{{
                      $t('Account settings')
                    }}</v-list-item-title>
                  </v-list-item>

                  <v-list-item max-width="250px" @click="logout()">
                    <template v-slot:prepend>
                      <v-icon icon="mdi-logout"></v-icon>
                    </template>
                    <v-list-item-title>{{ $t('Logout') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-theme-provider>
          </v-menu>
        </div>
      </div>
    </v-toolbar>
  </v-theme-provider>
</template>

<script setup lang="ts">
import { useAuthStore, useConfigStore } from '@/store';
import { switchLanguage, localePath } from '@/composables/index';
import { useRouter, useRoute } from 'vue-router';
import { onMounted, ref } from 'vue';
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();
const configStore = useConfigStore();

const userDetails = authStore.getUser;

// defineEmits<{
//   (e: 'toggleDrawer'): void;
// }>();
const disableTeleportation = ref(false);
let merchantDetails = {
  admin_url: '',
  logo_url: '',
  favicon_url: '',
  title: '',
  title_en: '',
  title_ar: '',
  subheader: '',
};

const logout = () => {
  authStore.getLogout?.();
};

const setMerchantConfigs = (data: any) => {
  const {
    admin_url,
    logo_url,
    favicon_url,
    title,
    title_en,
    title_ar,
    subheader,
  } = data;
  merchantDetails = {
    admin_url,
    logo_url,
    favicon_url,
    title,
    title_en,
    title_ar,
    subheader,
  };
};

onMounted(() => {
  setMerchantConfigs(configStore.getConfig);
});

defineExpose({
  disableTeleportation,
});
</script>

<style lang="scss" scoped>
.v-toolbar {
  background: black;
  :deep(.v-toolbar__content) {
    justify-content: space-between;
  }
}

.ottu-logo {
  padding-left: 3rem;
  margin-right: 10px;
  height: 40px;
  // @media screen and (max-width: 1280px) {
  //   padding-left: 1rem;
  // }
}
// .menu-icon {
//   display: none;
//   padding-left: 2px;
//   @media screen and (max-width: 1280px) {
//     display: block;
//   }
// }
.v-input {
  :deep(.v-field__input) {
    min-height: auto;
  }
}
.header-info {
  gap: 1rem;
  padding-right: 3rem;
  :deep(.v-badge__badge) {
    padding: 0;
  }
  .user-menu {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .user-img {
    height: 40px;
    width: 40px;
    border-radius: 6px;
  }
}
</style>

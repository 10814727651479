import { i18n } from '@/plugins/i18n';
import { RouteLocationNormalizedLoadedGeneric, Router } from 'vue-router';

export const switchLanguage = (
  route: RouteLocationNormalizedLoadedGeneric,
  router: Router,
) => {
  const lang = i18n.global.locale.value === 'ar' ? 'ar' : 'en';
  const target_lang = i18n.global.locale.value === 'en' ? 'ar' : 'en';
  i18n.global.locale.value = target_lang;
  const path = route?.fullPath?.replaceAll(`/${lang}/`, `/${target_lang}/`);
  router.push({ path: path });
};
export const localePath = (path: string) => {
  return `/${i18n.global.locale.value}${path}`;
};

export const dispatchSnackbar = (text = '', color = 'info') => {
  const event = new CustomEvent('snackbar', {
    detail: { text, color },
  });
  window.dispatchEvent(event);
};

import { defineStore } from 'pinia';

interface User {
  username?: string;
  is_superuser?: boolean;
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: null as string | null,
    logout: null as (() => void) | null,
    refreshToken: null as ((refreshToken?: string | null) => void) | null,
    user: {} as User,
    sharedMFState: null,
  }),
  getters: {
    getToken: (state) => state.token,
    getLogout: (state) => state.logout,
    getRefreshToken: (state) => state.refreshToken,
    getUser: (state) => state.user,
    getSharedMFState: (state) => state.sharedMFState,
  },
  actions: {
    setToken(val: string) {
      this.token = val;
    },
    setLogout(val: () => void) {
      this.logout = val;
    },
    setRefreshToken(val: (refreshToken?: string | null) => void) {
      this.refreshToken = val;
    },
    setUser(user: any) {
      this.user = user;
    },
    setSharedMFState(sharedState: any) {
      this.sharedMFState = sharedState;
    },
  },
});

export const useConfigStore = defineStore('config', {
  state: () => ({
    renewalDate: '',
    sections: null as unknown | null,
    idleTime: null as unknown | null | string,
    idleCountdownTimer: 60,
    merchant: '',
    isKeycloakAuth: false as boolean,
    isMerchantAdmin: false as boolean,
    keyCloakDomain: '',
    config: null as unknown | null,
    enableSessionTimeout: false as boolean,
  }),
  getters: {
    getRenewalDate: (state) => state.renewalDate,
    getSections: (state) => state.sections,
    getIdleTime: (state) => state.idleTime,
    getIdleCountdownTimer: (state) => {
      return state.idleCountdownTimer;
    },
    getIsKeycloakAuth: (state) => state.isKeycloakAuth,
    getMerchant: (state) => state.merchant,
    getIsMerchantAdmin: (state) => state.isMerchantAdmin,
    getKeyCloakDomain: (state) => state.keyCloakDomain,
    getConfig: (state) => state.config,
    getEnableSessionTimeout: (state) => state.enableSessionTimeout,
  },
  actions: {
    setRenewalDate(value: string) {
      this.renewalDate = value;
    },
    setSections(val: unknown) {
      this.sections = val;
    },
    setIdleTime(value: unknown) {
      this.idleTime = value;
    },
    setIdleCountdownTimer(value: number) {
      this.idleCountdownTimer = value;
    },
    setMerchant(value: string) {
      this.merchant = value;
    },
    setIsKeycloakAuth(val: boolean) {
      this.isKeycloakAuth = val;
    },
    setIsMerchantAdmin(value: boolean) {
      this.isMerchantAdmin = value;
    },
    setKeyCloakDomain(val: string) {
      this.keyCloakDomain = val;
    },
    setConfig(val: unknown) {
      this.config = val;
    },
    setEnableSessionTimeout(value: boolean) {
      this.enableSessionTimeout = value;
    },
  },
});

import { AuthorizedService } from './index';

const navigationService = AuthorizedService(`/v1/ui/dashboard`);

class NavigationService {
  static getNavigation() {
    return navigationService({
      method: 'GET',
      url: '/menu/',
    });
  }
}

export default NavigationService;
